import { createGlobalStyle } from 'styled-components'

// CSS
import './reset.css'
import './fonts.css'

const GlobalStyles = createGlobalStyle`
  body,
  div,
  dl,
  dt,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote {
    font-family: 'Inter-Regular';
    text-rendering: optimizeLegibility;
  }
  h1 {
    font-size: ${({ theme }) => theme.mobileSizes.h1};
  }
  h2 {
    font-size: ${({ theme }) => theme.mobileSizes.h2};
  }
  h3 {
    font-size: ${({ theme }) => theme.mobileSizes.h3};
  }
  h4 {
    font-size: ${({ theme }) => theme.mobileSizes.h4};
  }
  h5 {
    font-size: ${({ theme }) => theme.mobileSizes.h5};
  }
  .code-tab {
    font-weight: 300;
    z-index:1;
    position: absolute;
    top: 1px;
    right: 5%;
    margin-bottom:3px;
    color: ${({ theme }) => theme.colors.color2};
    font-size: ${({ theme }) => theme.desktopSizes.small};
    letter-spacing: 0.1em;
    transform: translateY(-100%);
    text-transform: uppercase;
    background: ${({ theme }) => theme.colors.color4};
    border: 1px solid ${({ theme }) => theme.colors.color5};
    border-bottom:none !important;
    padding-top:${({ theme }) => theme.spacings.xxSmall};
    padding-right:${({ theme }) => theme.spacings.small};
    padding-bottom:${({ theme }) => theme.spacings.xxSmall};
    padding-left:${({ theme }) => theme.spacings.small};
    border-top-left-radius:${({ theme }) => theme.spacings.xSmall};
    border-top-right-radius:${({ theme }) => theme.spacings.xSmall};
  }
  code {
    background: ${({ theme }) => theme.colors.color4};
    border: 1px solid ${({ theme }) => theme.colors.color5};
    border-radius: ${({ theme }) => theme.spacings.xxSmall};
    color: ${({ theme }) => theme.colors.color2} !important;
    padding:${({ theme }) => theme.spacings.xxSmall};
    font-size:90% !important;
  }
  kbd {
    padding: 0.1875rem 0.375rem;
    font-size: .875em;
    color: ${({ theme }) => theme.colors.color2} !important;
    background-color: ${({ theme }) => theme.colors.color4} !important;
    border-radius: 0.25rem;
    border: 1px solid ${({ theme }) => theme.colors.color5};
  }
  a:hover {
    transition: ${({ theme }) => theme.animations.trans1};
  }
  `

export default GlobalStyles
