const theme = {
  colors: {
    // https://coolors.co/generate
    // ${({ theme }) => theme.colors.};
    color1: `#1c978c`, // Viridian Green
    color2: `#161b29`, // Raisin Black
    color3: `#353b4a`, // Charcoal
    color4: `#f8fafb`, // Cultured
    color5: `#e5e7eb`, // Gainsboro
    color6: `#ffffff`, // White
    color7: `#a4a4a5`, // Quick Silver
    color8: `#C8CBCF`, // Silver Band
  },
  spacings: {
    // ${({ theme }) => theme.spacings.};
    xxSmall: '.25rem', // 4px
    xSmall: '.5rem', // 8px
    small: '1rem', // 16px
    medium: '2rem', // 32px
    large: '3rem', // 48px
    xLarge: '4rem', // 64px
    xxLarge: '6rem', // 96px
  },
  desktopSizes: {
    // https://type-scale.com/
    // Perfect Fourth
    // ${({ theme }) => theme.desktopSizes.};
    h1: `4.209rem`, // 67.34px
    h2: `3.157rem`, // 50.52px
    h3: `2.369rem`, // 37.90px
    h4: `1.777rem`, // 28.43px
    h5: `1.333rem`, // 21.33px
    p: `1rem`, // 16px
    small: `0.8rem`, // 12px
    xSmall: `0.64rem`, // 9px
    xxSmall: `0.512rem`, // 6.76px
  },
  mobileSizes: {
    // https://type-scale.com/
    // Minor Third
    // ${({ theme }) => theme.mobileSizes.};
    h1: `2.488rem`, // 39.81px
    h2: `2.074rem`, // 33.18px
    h3: `1.728rem`, // 27.65px
    h4: `1.44rem`, // 23.04px
    h5: `1.2rem`, // 19.20px
  },
  breakpoints: {
    sm: `600px`,
    md: `960px`,
    lg: `1280px`,
    xl: `1920px`,
  },
  animations: {
    trans1: `8s infinite linear`,
    trans2: `all 0.8s ease-in-out`,
    trans3: `all 0.4s ease`,
  },
  aside: {
    primary: `300px`,
    secondary: `200px`,
  },
}

export default theme
