import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Gist from 'react-gist'

const GistContainer = styled.div``

const GistHeader = styled.p`
  margin-bottom: ${({ theme }) => theme.spacings.xxSmall};
  margin-left: ${({ theme }) => theme.spacings.xSmall};
  font-weight: 500;
`

const Gister = ({ id }) => {
  return (
    <GistContainer>
      <GistHeader>Gist:</GistHeader>
      <Gist id={id} />
    </GistContainer>
  )
}

Gister.propTypes = {
  id: PropTypes.string,
}

export default Gister
