import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap/'
import styled from 'styled-components'

// React Icons
import { BsInfoCircleFill } from 'react-icons/bs'
import { IoMdWarning } from 'react-icons/io'
import { RiCheckboxCircleFill } from 'react-icons/ri'

const MessageContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacings.xxSmall};
  align-items: center;
`

const MessageIcon = styled.div`
  font-size: ${({ theme }) => theme.mobileSizes.h4};
  align-items: center;
`

const MessageHeader = styled.div`
  font-weight: 600;
  margin-left: ${({ theme }) => theme.spacings.xxSmall};
`

const MessageChildren = styled.div``

const header = (variant, msg) => {
  const v = variant.toString().trim().toLowerCase()
  switch (v) {
    case 'primary':
      return (
        <MessageContainer>
          <MessageIcon>
            <BsInfoCircleFill />
          </MessageIcon>
          <MessageHeader>{msg === '' ? 'Important' : msg}</MessageHeader>
        </MessageContainer>
      )
    case 'warning':
      return (
        <MessageContainer>
          <MessageIcon>
            <IoMdWarning />
          </MessageIcon>
          <MessageHeader>{msg === '' ? 'Warning' : msg}</MessageHeader>
        </MessageContainer>
      )
    case 'danger':
      return (
        <MessageContainer>
          <MessageIcon>
            <IoMdWarning />
          </MessageIcon>
          <MessageHeader>{msg === '' ? 'Danger' : msg}</MessageHeader>
        </MessageContainer>
      )
    case 'success':
      return (
        <MessageContainer>
          <MessageIcon>
            <RiCheckboxCircleFill />
          </MessageIcon>
          <MessageHeader>{msg === '' ? 'Success' : msg}</MessageHeader>
        </MessageContainer>
      )

    default:
      return
  }
}

const AlertMessage = ({ variant, msg, children }) => {
  return (
    <Alert variant={variant}>
      {header(variant, msg)}
      <MessageChildren>{children}</MessageChildren>
    </Alert>
  )
}

AlertMessage.defaultProps = {
  msg: '',
}

AlertMessage.propTypes = {
  variant: PropTypes.string,
  msg: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default AlertMessage
