import React from 'react'
import styled from 'styled-components'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/nightOwlLight'

const PreContainer = styled.div`
  overflow-x: auto !important;
  margin-top: -${({ theme }) => theme.spacings.xSmall};
`

const Pre = styled.pre`
  margin-top: ${({ theme }) => theme.spacings.medium};
  padding: ${({ theme }) => theme.spacings.small};
  background-color: ${({ theme }) => theme.colors.color4} !important;
  color: ${({ theme }) => theme.colors.color2};
  background: ${({ theme }) => theme.colors.color4};
  border: 1px solid ${({ theme }) => theme.colors.color5};
  white-space: pre-wrap;
`

const Line = styled.div`
  display: table-row;
`

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`

const LineContent = styled.div`
  display: table-cell;
`

const PrismPre = props => {
  const className = props.children.props.className
  const language = className === undefined ? 'default' : className.split('-')[1]

  return className === '' ? (
    ''
  ) : (
    <PreContainer>
      <Highlight
        {...defaultProps}
        code={props.children.props.children.trim()}
        language={language}
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => {
          return (
            <div style={{ position: 'relative' }}>
              <Pre className={className} style={style}>
                <div className="code-tab">{language}</div>
                {tokens.map((line, i) => (
                  <Line {...getLineProps({ line, key: i })}>
                    <LineNo>{i + 1}</LineNo>
                    <LineContent>
                      {line.map((token, key) => (
                        <span key={key} {...getTokenProps({ token, key })} />
                      ))}
                    </LineContent>
                  </Line>
                ))}
              </Pre>
            </div>
          )
        }}
      </Highlight>
    </PreContainer>
  )
}

export default PrismPre
