import React, { createContext, useState } from 'react'

// Component
import HandleWindowSize from './HandleWindowSize'

const defaultContext = {
  windowWidth: undefined,
  windowHeight: undefined,
}

export const LayoutContext = createContext(defaultContext)

const LayoutProvider = ({ children }) => {
  const { windowWidth, windowHeight } = HandleWindowSize()
  const [headerHeight, setHeaderHeight] = useState(0)

  return (
    <LayoutContext.Provider
      value={{
        windowWidth,
        windowHeight,
        headerHeight,
        setHeaderHeight,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutProvider
