import React from 'react'
import { ThemeProvider } from 'styled-components'
import { MDXProvider } from '@mdx-js/react'

// Theme
import Theme from './src/theme'
import GlobalStyles from './src/theme/GlobalStyles'

// Context
import LayoutProvider from './src/context/LayoutProvider'

// Prism
import PrismPre from './src/components/Prism/PrismPre'

const components = {
  pre: PrismPre,
}

const RootWrapper = ({ element }) => (
  <LayoutProvider>
    <ThemeProvider theme={Theme}>
      <GlobalStyles />
      <MDXProvider components={components}>{element}</MDXProvider>
    </ThemeProvider>
  </LayoutProvider>
)

export default RootWrapper
